import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { typography, color } from 'styled-system';
import { device } from '../../../theme';

export const WidgetTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  color: ${themeGet('colors.headingColor')};
  border-color: ${themeGet('colors.borderColor')};
  margin-bottom: 25px;
`;

export const WidgetBoxWrap = styled.div`
  padding: 20px;
  border-radius: 5px;
  ${device.large} {
    padding: 30px;
  }
  ${(props) =>
    props.skin === 'primary' &&
    css`
      border: 1px solid ${themeGet('colors.borderColor')};
      background: ${themeGet('colors.themeColor')};
      ${WidgetTitle} {
        color: #fff;
        border-color: #fff;
      }
    `}
  ${(props) =>
    props.skin === 'secondary' &&
    css`
      border: 2px solid ${themeGet('colors.themeColor')};
      background: transparent;
      color: ${themeGet('colors.textColor')};
      ${WidgetTitle} {
        border-color: ${themeGet('colors.themeColor')};
        border-width: 2px;
      }
    `}
    ${color}
    ${typography}
    ${WidgetTitle} {
      ${color}
      ${typography}
    }
`;
