import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { Container, Row, Col } from 'reactstrap';
import SEO from '../../components/seo';
import Layout from '../../containers/layout/layout';
import Header from '../../containers/layout/header';
import Footer from '../../containers/layout/footer';
import SubscribeWidget from '../../containers/widgets/subscribeBox';
import { DateWrap, Category, Tags } from '../../components/blog/blog-meta';
import {
  Thumbnail,
  Video,
  Quote,
  Linked,
  Gallery,
} from '../../components/blog/blog-media';
import ModalVideo from '../../components/shared/modal-video';
import { slugify } from '../../utils/utilFunctions';
import { SearchWidget } from '../../containers/widgets/search';
import RelatedPosts from '../../containers/global/related-posts';
import SocialShare from '../../components/socials/social-share';
import {
  SinglePostArea,
  SinglePostWrap,
  PostMedia,
  PostHeader,
  PostTitle,
  PostMeta,
  PostFooter,
  PostShare,
  PostTags,
} from './single-blog.stc';

interface SingleBlogProps {
  data: {
    markdownRemark: {
      fields: any;
      html: string;
      frontmatter: {
        [key: string]: any;
        tags: string[];
      };
    };
  };
  pageContext: any;
  location: {
    pathname: string;
  };
}

const SingleBlog: React.FC<SingleBlogProps> = ({
  data,
  pageContext,
  location,
  ...restProps
}) => {
  const { dateSlug, slug } = data.markdownRemark.fields;
  const {
    category,
    date,
    format,
    title,
    image,
    video_link,
    quote_text,
    quote_author,
    link,
    images,
    author,
    tags,
  } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  let video_arr: string[], video_id, video_channel;
  if (video_link) {
    video_arr = video_link.split('=', -1);
    video_id = video_arr[1];
    video_channel = video_link.split('.')[1];
  }
  const [videoOpen, setVideoOpen] = useState(false);
  const modalVideoOpen = () => {
    setVideoOpen(true);
  };
  const modalVideoClose = () => {
    setVideoOpen(false);
  };

  return (
    <Layout>
      <SEO
        {...data.markdownRemark.frontmatter}
        image={image?.childImageSharp.fluid.src}
        type="article"
        path={`/${slug}`}
      />
      <Header />
      <div className="main-content">
        <SinglePostArea {...restProps}>
          <Container>
            <Row>
              <Col>
                <SinglePostWrap>
                  <PostMedia>
                    {(format === 'image' || format === 'standard') && (
                      <Thumbnail
                        path={`/${slug}`}
                        image={image}
                        title={title}
                      />
                    )}
                    {format === 'video' && (
                      <Video
                        onClick={modalVideoOpen}
                        poster={image.childImageSharp.fluid}
                        title={title}
                      />
                    )}
                    {format === 'quote' && (
                      <Quote text={quote_text} author={quote_author} />
                    )}
                    {format === 'link' && <Linked link={link} />}
                    {format === 'gallery' && <Gallery images={images} />}
                  </PostMedia>
                  <PostHeader>
                    {category && (
                      <Category
                        slug={`/category/${slugify(category)}`}
                        text={category}
                      />
                    )}
                    {title && <PostTitle>{title}</PostTitle>}
                    <PostMeta>
                      {date && (
                        <DateWrap>
                          <Link to={`/date/${slugify(dateSlug)}`}>{date}</Link>
                        </DateWrap>
                      )}
                    </PostMeta>
                  </PostHeader>
                  <div
                    className="post-content"
                    dangerouslySetInnerHTML={{ __html: html }}
                  />

                  <PostFooter>
                    <PostShare>
                      <h4>Share This:</h4>
                      <SocialShare title={title} slug={slug} />
                    </PostShare>
                    {tags?.length && (
                      <PostTags>
                        <Tags tags={tags} />
                      </PostTags>
                    )}
                    {/* {author && <AuthorWidget content={author} />} */}
                  </PostFooter>
                </SinglePostWrap>
                <SubscribeWidget />
                <RelatedPosts
                  category={category}
                  tags={tags}
                  currentArticleSlug={slug}
                />
                <SearchWidget />
              </Col>
            </Row>
          </Container>
        </SinglePostArea>
      </div>
      <Footer />
      <ModalVideo
        channel={video_channel}
        videoId={video_id}
        isOpen={videoOpen}
        onClose={modalVideoClose}
      />
    </Layout>
  );
};

export const postQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        dateSlug
        authorId
        slug
        postID
      }
      frontmatter {
        title
        category
        tags
        date(formatString: "LL")
        format
        description
        canonical
        image {
          childImageSharp {
            fluid(
              maxWidth: 839
              maxHeight: 455
              quality: 100
              srcSetBreakpoints: 6
            ) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
        author {
          name
          tagline
          image {
            childImageSharp {
              fixed(width: 180, height: 180, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          social {
            medium
            instagram
            linkedin
            twitter
            github
            soundcloud
          }
        }
      }
      html
    }
  }
`;

export default SingleBlog;
