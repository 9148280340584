import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { FiSearch } from 'react-icons/fi';
import WidgetBox, { WidgetTitle } from '../../../components/shared/widget-box';
import Form, { Input } from '../../../components/shared/form';
import styles from './searchWidget.module.scss';

export const SearchWidget = ({ widgetStyle, inputStyle }) => {
  const [value, setValue] = useState('');
  const onChangeHandler = (event) => setValue(event.target.value);
  const onSubmitHandler = (event) => {
    event.preventDefault();
    const query = value
      .toLowerCase()
      .trim()
      .replace(/[^\w ]/g, '');
    // .replace(/\&+/g, '-')

    navigate(`/search?query=${query}`, { state: { query } });
  };
  return (
    <WidgetBox {...widgetStyle} className={styles.widgetBox}>
      <WidgetTitle>Search content</WidgetTitle>
      <div>
        <Form onSubmit={onSubmitHandler}>
          <Input
            {...inputStyle}
            onChange={onChangeHandler}
            type="text"
            name="search"
            id="search"
            placeholder="Search Here..."
          />
          <div>
            <button type="submit" className={styles.button}>
              <FiSearch /> Search
            </button>
          </div>
        </Form>
      </div>
    </WidgetBox>
  );
};

SearchWidget.defaultProps = {
  widgetStyle: {
    skin: 'secondary',
  },
  inputStyle: {
    pr: '50px',
    borderColor: 'var(--theme-color)',
    fontSize: '18px'
  },
};
