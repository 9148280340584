import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const FormWrapper = styled.div``;

export const SubscribeText = styled.p`
  color: ${themeGet('colors.textColor')};
  font-size: 18px;
  margin-bottom: 26px;
  margin-top: -10px;
`;
