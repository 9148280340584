import styled from 'styled-components';
import { space, layout } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import React from 'react';

export const SectionTitleWrap = styled.div`
  ${space}
  ${layout}
    h3 {
    font-size: 24px;
    text-transform: uppercase;
    position: relative;
    &:after {
      position: absolute;
      content: '';
      left: 0px;
      bottom: 6px;
      width: 120px;
      height: 8px;
      z-index: -1;
      background-color: ${themeGet('colors.themeColor')};
    }
  }
`;

interface SectionTitleProps {
  title?: string;
  subtitle?: string;
  align?: 'left' | 'center' | 'right';
  mb?: string;
  mt?: string;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  subtitle,
  ...props
}) => {
  return (
    <SectionTitleWrap {...props}>
      {title && <h3 dangerouslySetInnerHTML={{ __html: title }} />}
      {subtitle && <h6>{subtitle}</h6>}
    </SectionTitleWrap>
  );
};

SectionTitle.defaultProps = {
  align: 'left',
};
