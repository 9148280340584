import React from 'react';
import WidgetBox, { WidgetTitle } from '../../../components/shared/widget-box';
import Mailchimp from '../../../components/forms/mailchimp-two';
import Heading from '../../../components/shared/heading';
import { FormWrapper, SubscribeText } from './subscribe.stc';

const SubscribeWidget = ({ widgetStyle, widgetTitleStyle }) => {
  const [isSuccess, setSuccess] = React.useState(false);
  return (
    <WidgetBox {...widgetStyle}>
      {isSuccess ? (
        <Heading as="h2">Thank you for subscribing!</Heading>
      ) : (
        <>
          <WidgetTitle {...widgetTitleStyle}>Subscribe for more</WidgetTitle>
          <FormWrapper>
            <SubscribeText>
              If you liked this article, please subscribe to be notified of more
              articles like this. I'll also be sneding some free goodies to your
              way. Plus I'll never spam you{' '}
              <span aria-label="thumbs up" role="img">
                👍
              </span>
            </SubscribeText>
            <Mailchimp setSuccess={setSuccess} backgroundTheme="light" />
          </FormWrapper>
        </>
      )}
    </WidgetBox>
  );
};

SubscribeWidget.defaultProps = {
  widgetStyle: {
    skin: 'secondary',
  },
  widgetTitleStyle: {
    color: '#fff',
  },
};

export default SubscribeWidget;
